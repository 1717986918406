/* ColumnsWithIcons.css */

.columns-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center align columns */
  background-color: white;
  padding: 50px; /* Adjust padding */
  box-sizing: border-box;
  /* background-image: url('/public/page.png');  */
  background-image: url('/public/alter.png');
}

.column {
  flex: 1 1 330px; /* Adjust flex properties for better responsiveness */
  max-width: 350px; /* Set maximum width for columns */
  margin: 15px;
  padding: 15px;
  position: relative;
  overflow: hidden;
  transition: transform 0.2s, box-shadow 0.3s;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  
}

.column:hover {
  transform: scale(1.05); /* Increase size on hover */
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2); /* Enhance box shadow on hover */
  border:  0.2px solid black;
}

.icon {
  font-size: 2em;
  margin-bottom: 10px;
  color: white;
  background-color: green;
  border-radius: 50%;
  padding: 15px;
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
}

.img-src-feat{
  width: 100%;
  height: auto;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: #cdf683;
  border-radius: 50%;
  overflow: hidden;
}

.column h3 {
  color: black;
  /* margin: 0; */
  /* padding-left: 10%; */
  text-align: center;
  white-space: nowrap;
  /* display: inline; */
  font-size: 1.2em;
}

.column p {
  color: #333; /* Darker shade of black for better readability */
  font-size: 16px; /* Adjust the font size as per your design */
  line-height: 1.6; /* Increase line height for better readability */
  font-family: Arial, sans-serif; /* Choose a suitable font-family */
  margin: 0; /* Remove any default margin */
  padding-left: 8px; /* Increase padding for better spacing */
}

.button-group{
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Animation for Our Popular Products Starts */
.tracking-in-contract-bck-bottom {
	-webkit-animation: tracking-in-contract-bck-bottom 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: tracking-in-contract-bck-bottom 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}


 @-webkit-keyframes tracking-in-contract-bck-bottom {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px) translateY(300px);
            transform: translateZ(400px) translateY(300px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
@keyframes tracking-in-contract-bck-bottom {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px) translateY(300px);
            transform: translateZ(400px) translateY(300px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}


/*  */

/* Responsive styles */
@media (max-width: 1200px) {
  .column {
    flex: 1 1 33.33%;
  }
}

@media (max-width: 768px) {
  .column {
    flex: 1 1 50%;
  }
}

@media (max-width: 480px) {
  .column {
    flex: 1 1 100%;
  }
}

/* Responsive for columns */
@media (max-width: 1200px) {
  .columns-container {
    padding: 40px;
  }
}

@media (max-width: 768px) {
  .columns-container {
    padding: 20px;
  }
}

@media (max-width: 480px) {
  .columns-container {
    padding: 10px;
  }
}
