/* Top.css */

.top-container {
    display: flex;
    background-color: #aba9e5;
  }
  
  .left-part {
    padding: 20px;
    /* background-color: #f7f0d8; */
    color: black;
    font-size: larger;
  }
  
  
  .right-part {
    padding: 20px;
    display: flex;
    align-items: center;
    /* background-color: #f7f0d8; */
  }
  
  .toptext {
    color: #777775;
  }

  .topbtn {
    background-color: #000;
    color: white;
    border-radius: 5px;
    padding: 15px;
    transition: background-color 0.3s; /* Smooth transition on background color change */
  }
  
  .topbtn:hover {
    background-color: #cdf683; /* Change to green on hover */
    color:#000;
  }
  
  .icon-text-1 {
    display: flex; /* Use flexbox for horizontal alignment */
    align-items: center; /* Align items vertically in the center */
    justify-content: center;
  }
  
  .icon-text-1 > * {
    margin-right: 5px; /* Add margin between icon and text */
  }
  
/* Animation for h1 amd p starts */

.tracking-in-contract {
	-webkit-animation: tracking-in-contract 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: tracking-in-contract 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}



 @-webkit-keyframes tracking-in-contract {
  0% {
    letter-spacing: 1em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}
@keyframes tracking-in-contract {
  0% {
    letter-spacing: 1em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}


/*  */

  .top-head {
    font-family: 'Times New Roman', Times, serif;
    font-weight:bold;
  }

  .top-para{
    font-family: 'Times New Roman', Times, serif;
    font-weight:bold;
    font-size: 1em;
  }
  @media (max-width: 768px) {
    .top-container {
      display: flex;
      flex-direction: column;
      background-color: #aba9e5;
    }
    .left-part {
      padding: 15px 15px 0 15px;
      /* background-color: #f7f0d8; */
      color: black;
      font-size: larger;
    }
    .top-head{
      font-size: 25px;
      text-align: center;
    }
    .top-para{
      font-weight:100;
      margin: 0px;
      text-align: center;
      font-size: 20px;
    }
    .right-part {
      padding: 15px;
    }
  }