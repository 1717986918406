/* RoundButton.css */

.round-button {
    background-color: #3498db;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    padding: 10px;
    position: fixed;
    bottom: 20px; /* Adjust the distance from the bottom as needed */
    left: 20px; /* Adjust the distance from the left as needed */
    z-index: 1000; /* Ensure the button stays above other elements on the page */
  }
  
  .button-logo {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: block;
    margin: auto;
  }
  