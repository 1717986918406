.accordion {
    /* border: 1px solid #ddd; */
    margin-bottom: 2%;
    background-color: #0303cd;
    border-radius: 8px;
    color: white;
    }
  .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px;
    cursor: pointer;
    font-size: 1.2em;
    /* background-color: #e9f7e0; */
    
    
  }
  
  .accordion-header button {
    background-color: #191919;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .accordion-header button.green-bg {
    background-color: #cdf683;
  }
  
  .accordion-content {
    padding: 10px;
    /* background-color: #e9f7e0; */
    /* border-radius: 10px; */
  }

  .acc-body{
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: -40%;  
  }
  
  
  
  .rounded-button {
    border-radius: 4px; /* Adjust the border radius as needed */
    
    border: 2px solid #000; /* Add border styles as needed */
    /* Add any other external styles for the button */
  }
  