/* LineDot.css */

.line-dot-container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leftline-column,
.rightline-column {
  display: flex;
  flex-direction: column;
  position: absolute;
}

.leftline-column {
  left: 500px;
}

.rightline-column {
  right: 500px;
}

.left-line-1{
  transform: scale(-1); /* Mirror the element horizontally */
  margin-right: 500px;
}


.left-line-2{
  transform: scaleX(-1); /* Mirror the element horizontally */
  /* margin-right: 550px; */
}


.right-line-2 {
  margin-bottom: 200px; /* Adjust the margin as needed */
  
}

.right-line-1{
  transform: scaleY(-1); /* Mirror the element horizontally */
  margin-left: 70px;
}


.right-line-2 {
  margin-bottom: 20px; /* Adjust the margin as needed */
  transform: scaleX(-1); /* Mirror the element horizontally */
}

.left-line-2{
  margin-bottom: 17px; /* Adjust the margin as needed */
  transform: scaleY(-1); /* Mirror the element horizontally */
}

.left-line-3{
  margin-right: 436px;
  transform: scaleX(-1); /* Mirror the element horizontally */
}

.right-line-3{
  margin-left: 50px;
 
}

.dotimg {
  position: absolute;
  width: 150px;
  height: auto;
  object-fit: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
