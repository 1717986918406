/* Navbar.css */
/* 
.sidemenu {
  height: 40%;
  transition: all 0.5s;
  width: 88%;
  font-size: 10px;
  padding: 0px 12px;
  background-color: white;
  color: #000;
  font-family: Roboto, sans-serif;
  border: none;
  border-radius: 12px;
  box-shadow: 2px 2px 0px hsl(0, 0%, 90%),
    4px 4px 0px hsl(0, 0%, 80%),
    6px 6px 0px hsl(0, 0%, 70%),
    8px 8px 0px hsl(0, 0%, 60%),
    9px 9px 0px hsl(0, 0%, 80%);
}

.sidemenu:hover {
  background-color: hsl(0, 0%, 50%);
  color: #800685;
  box-shadow: none;
} */






.mynavbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  z-index: 1000;
  box-shadow: 0 0 20px;
  margin: 10px;
  width: 98%;
  border-radius: 15px;
  box-shadow: 2px 2px 0px hsl(0, 0%, 90%),
  4px 4px 0px hsl(0, 0%, 80%),
  6px 6px 0px hsl(0, 0%, 70%),
  8px 8px 0px hsl(0, 0%, 60%),
  9px 9px 0px hsl(0, 0%, 80%);
}

.logo img {

  width: 81px;
  height: 76px;
}

.nav-links {
  background-color: #1237ab;
  color: #ffffff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 950px;
  border-radius: 8px;
  box-shadow: 2px 2px 0px #2b4eb6,
  4px 4px 0px #425fb6,
  6px 6px 0px #4d66b1,
  8px 8px 0px #6377b4,
  9px 9px 0px #6f80b6;
}

.nav-links .a {
  text-decoration: none;
  color: #ffffff;
  transition: background-color 0.3s;
}

.nav-links .a:hover {
  /* background-color: #333333; */
  color: grey;
}

/* Product dropdon starts here */
.products {
  position: relative;
  padding: 20px;
  /* margin-bottom: 0px;
  margin-top: 0px; */

}

.products-dropdown {
  position: absolute;
  top: 100%;
  left: -20px;
  background-color: white;
  /* background-image: linear-gradient(to right,#8314af 0%,#1c368d 51%,#232189 100%); */
  color: black;
  padding: 20px;
  width: 1000px;
  height: fit-content;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease;
  border: 2px solid black !important;
  box-shadow: 2px 2px 8px black;
}

.products-dropdown-modal {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style-type: none;
  /* background-image: linear-gradient(to right,#8314af 0%,#1c368d 51%,#232189 100%); */
  /* background-color: white; */
}

.products-dropdown-items {
  width: 50%;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 2.5;
  background-color: white;
}

.products-dropdown-items a {
  text-decoration: none;
  color: white
}

.products-dropdown-items:hover {
  text-decoration: none;
  background-color: #fdf0e8;
  padding: 1px;
  border-radius: 10px;

}

.dropdown-span {
  font-size: 20px;
}

.products:hover .products-dropdown {
  opacity: 1;
  visibility: visible;
}

/* Solutions dropdon ends here */

.solutions {
  position: relative;
  padding: 20px;
}

.solutions-dropdown {
  position: absolute;
  top: 100%;
  left: -55px;
  background-color: white;
  /* background-image: linear-gradient(to right,#8314af 0%,#1c368d 51%,#232189 100%); */
  color: black;
  padding: 20px;
  width: 950px;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease;
  border: 2px solid black !important;
  box-shadow: 2px 2px 8px black;
}

.solutions-dropdown-modal {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style-type: none;
}

.solutions-dropdown-items {
  width: 50%;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 2.5;
}

.solutions-dropdown-items a {
  text-decoration: none;
  color: black;

}

.dropdown-span {
  font-size: 20px;
}

.solutions:hover .solutions-dropdown {
  opacity: 1;
  visibility: visible;
}

.solutions-dropdown-items:hover {
  text-decoration: none;
  background-color: #fdf0e8;
  padding: 1px;
  /* border-radius: 10px; */
  border-right: 1px solid black;
  border-bottom: 1px solid black;



}

/* Solutions dropdon starts here */


/* Product dropdon ends here */

/* Toggler button styles */
.navbar-toggler {
  display: none;
  /* Hide by default for larger screens */
}

.square-icon {
  display: none;
}

/* Media query for smaller screens */
@media (max-width: 992px) {
  .navbar-toggler {
    display: block;
    /* Show toggler button for smaller screens */
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .navbar-toggler .navbar-toggler-icon {
    display: block;
    width: 30px;
    height: 30px;
    background-color: #333;
    transition: transform 0.3s ease;
  }

  .navbar-toggler.open .navbar-toggler-icon {
    transform: rotate(45deg);
    /* Adjust rotation for open state */
  }

  .nav-links {
    position: fixed;
    top: 0;
    left: -100%;
    /* Hide off-canvas menu by default */
    width: 70%;
    /* Adjust width of off-canvas menu */
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: normal;
    z-index: 999;
    transition: left 0.3s ease;
    overflow-y: auto;
  }

  .nav-links.show {
    left: 0;
    /* Show off-canvas menu when toggler button is clicked */
    background-color: black;
    /* background color for the off-canvas navbar */
  }

  .nav-links a {
    margin: 20px 0;
    color: #333;
    text-decoration: none;
    font-size: larger;
    position: relative;
  }

  .nav-links .a:hover {
    color: #ffffff;
  }

  .home,
  .about,
  .products,
  .solutions,
  .partners,
  .resources,
  .support,
  .contact {
    width: 100%;
    padding: 10px;
    text-align: center;
    margin: 4px;
  }

  .products-dropdown {
    position: absolute;
    top: 100%;
    left: 0px;
    background-color: black;
    color: black;
    padding: 5px;
    width: 100%;
    border-radius: 0px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease;

  }

  .products-dropdown-modal {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style-type: none;
    margin: 0;

  }

  /* .products-dropdown-items{
    width: 100%;
    padding: 2px;
    box-sizing: border-box;
    font-size: 10px;
    line-height: 2.0;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    border-radius: 10px;
  }
  
  .products-dropdown-items a{
    text-decoration: none;
    color: white;
  }
  
  .products-dropdown-items:hover {
    text-decoration: none;
    background-color: rgb(99, 99, 99);
    color: white;
    padding:1px;
    border-radius: 10px;
  } */
  /* .products-dropdown-items a:hover {
    color: black;
  } */

  .dropdown-span {
    display: none;
  }

  .products:hover .products-dropdown {
    opacity: 0;
    visibility: hidden;
  }

  .products-dropdown.show {
    opacity: 1 !important;
    ;
    visibility: visible !important;
    ;
    position: static;
  }

  .solutions-dropdown {
    position: absolute;
    top: 100%;
    left: 0px;
    background-color: black;
    color: black;
    padding: 5px;
    width: 100%;
    border-radius: 0px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease;

  }

  .solutions-dropdown-modal {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style-type: none;
    margin: 0;
  }

  .solutions:hover .solutions-dropdown {
    opacity: 0;
    visibility: hidden;
  }

  .solutions-dropdown.show {
    opacity: 1 !important;
    ;
    visibility: visible !important;
    ;
    position: static;
  }
  .square-icon {
    position: absolute;
    width: 20px;
    height: 20px;
    /* border: 1px solid white; */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    right: -85px;
    transform: translate(-50%, -50%);
    padding: 13px;
  }

  .language-scheduleDemo {
    display: none;
  }

  .btn-btn-primary {
    font-size: 12px;
  }

  .menu-button {
    font-size: 40px;
    width: 40px;
    height: 40px;
  }
}

/* Style for schedule btn starts here */
.btn-btn-primary {
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #1237ab;
}

.btn-btn-primary:hover {

  /* border-top-left-radius: 10px; */
  /* border-bottom-right-radius: 10px ; */
  background-image: linear-gradient(to right, #8314af 0%, #1c368d 51%, #232189 100%);
  color: white;
}

/* Style for schedule btn ends here */