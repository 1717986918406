.contact-us-container {
    padding: 20px;
    margin-top: 100px;
    background-color: #dcdbff;
    /* background-image: url('/public/q.svg'); */
    background-image: url('/public/blob.svg'); 
  }
  
  .hehe{
    text-align: center;
    /* margin-bottom: 10%; */
    margin-top: 10%;
    font-size: 45px;
    font-weight: bolder;
    /* background-color: yellow; */
    background-image: url('/public/der.svg');
    background-size: 50%;;
  }
  
  .contact-content {
   
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .contact-form {
    flex: 1;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 20px;
    width: 50%;
    
  }
  
  .form-group {
    margin-bottom: 20px;
    /* margin-left: 100px; */
    
   
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
  }
  
  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .contact-info {
    flex: 1;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
  
  .ci {
    margin-bottom: 20px;
    background-color: #27272e;
    border-radius: 20px;
    padding: 30px;
  }
  
  .ci h2 {
    margin-left: 40px;
    color: white;
    margin-bottom: 30px;
    font-size: 27px;
    font-weight: bolder;
  }

  .leag{
    width: 500px;
    margin-left: 70%;
    margin-bottom: -40%;
  }
  
/* Styles for Responsive Design. */

  @media (max-width:1300px) {
  .contact-content{
      flex-direction: column;
      height: auto;
    }

  .contact-form,
  .contact-info {
    flex: 1;
    width: 100%;
    margin-bottom: 20px; /* Add some spacing between columns on smaller screens */
  }


    .leag{
      width:300px;
      margin-bottom: 0px;
    }
    .hehe{
      margin-top: 125px;
      margin-bottom: 0px;
      font-size: 20px;
    }
  }

/* Responsive design for form */

  @media (max-width:480px) {
    .contact-content{
      flex-direction: column;
      height: auto;
    }
  .contact-form,
  .contact-info {
    flex: 1;
    width: 100%;
    margin-bottom: 20px; /* Add some spacing between columns on smaller screens */
  }
    .leag{
      display: none;
      margin-bottom: 0px;
    }
    .hehe{
      margin-top: 125px;
      margin-bottom: 0px;
      font-size: 20px;
    }
  }