* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: #212121;
}

.container_x {
  width: 200px !important;
  color: white;
  position: relative;
  font-family: sans-serif;
}

.container_x::before,
.container_x::after {
  content: "";
  background-color: #fab5704c;
  position: absolute;
}

.container_x::before {
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  top: 30%;
  right: 7%;
}

.container_x::after {
  content: "";
  position: absolute;
  height: 3rem;
  top: 8%;
  right: 5%;
  border: 1px solid;
}

.container_x .box_2 {
  width: 11.875em;
  height: 11.875em;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.074);
  border: 1px solid rgba(255, 255, 255, 0.222);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  border-radius: 0.7rem;
  transition: all ease 0.3s;
}

.container_x .box_2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container_x .box_2 .title {
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.1em;
}

.container_x .box_2 div strong {
  display: block;
  margin-bottom: 0.5rem;
}

.container_x .box_2 div p {
  margin: 0;
  font-size: 0.9em;
  font-weight: 300;
  letter-spacing: 0.1em;
}

.container_x .box_2 div span {
  font-size: 0.7rem;
  font-weight: 300;
}

.container_x .box_2 div span:nth-child(3) {
  font-weight: 500;
  margin-right: 0.2rem;
}

.container_x .box_2:hover {
  box-shadow: 0px 0px 20px 1px #ffbb763f;
  border: 1px solid rgba(255, 255, 255, 0.454);
}

.row {
  margin-right: 0px !important;
}

.outer_container_x {
  position: relative;
  justify-content: center;
  padding: 20px;
  background-image: url(../../public/moving_bg.png);
}

.outer_container_x::after {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  content: '';
  height: 300px;
  /* background: red; */
  /* background-image: url('http://localhost:3000/static/media/alter.01e319fb885476c3f7f0.png'); */
  z-index: 0;
}

.outer_container_x>* {
  z-index: 5;
}

iframe {
  width: 100vw;
  height: 100vh;
}