
.cardNew {    border: 1px saddlebrown;
  max-width: 205px;
  height: 105px;
  background: #243137;
  BACKGROUND: linear-gradient(90deg, rgb(255 255 255) 0%, #b5b5bd 35%, rgb(0 0 0) 100%);
  background-image: linear-gradient(19deg, rgb(230 250 255) 0%, rgb(90 81 207) 100%);
  position: relative;
  margin: 9px;
  display: grid;
  place-content: center;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  border: 1px solid black;
  box-shadow: 0 0 5px #0012e9;
}
.logo_ img{
  max-width: inherit !important;
}

#logo_-main, #logo_-second {
  height: 100%;
}

#logo_-main {
  fill: #bd9f67;
}

#logo_-second {
  padding-bottom: 10px;
  fill: none;
  stroke: #bd9f67;
  stroke-width: 1px;
}

.border {
  position: absolute;
  inset: 0px;
  border: 2px solid #bd9f67;
  opacity: 0;
  transform: rotate(10deg);
  transition: all 0.5s ease-in-out;
}

.bottom-text {
  position: absolute;
  left: 50%;
  bottom: 13px;
  transform: translateX(-50%);
  font-size: 10px;
  text-transform: uppercase;
  padding: 0px 5px 0px 8px;
  color: #bd9f67;
  background: #243137;
  opacity: 0;
  letter-spacing: 7px;
  transition: all 0.5s ease-in-out;
}

.content {
  transition: all 0.5s ease-in-out;
}
.content .logo_ {    
  height: 85px;
  position: relative;
  width: 156px;
  overflow: hidden;
  transition: all 1s ease-in-out;
}
.content .logo_ .logo_1 {
  display: flex;
  width: 100%;
  opacity: 1;
  justify-content: space-between;
  height: 33px;
  position: absolute;
  left: 0;
  FONT-SIZE: 18PX;
  transition: 1s all ease-in-out;
  FONT-WEIGHT: BOLD;
  color: white;
}
.content .logo_ .logo_1 img{
  height: 66px;
  max-width: 80px !important;
  margin-top: 15px;
}

.content .logo_ .logo_2 {
  height: 33px;
  opacity: 0;
  position: absolute;
  left: 60px;
  FONT-SIZE: 17PX;
  transition: 1s all ease-in-out;
  COLOR: WHITE;
  FONT-WEIGHT: BOLD;
  width: 150px !important;
  text-align: center;
}

.content .logo_ .trail {
  position: absolute;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.content .logo_-bottom-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: 30px;
  color: #bd9f67;
  padding-left: 8px;
  font-size: 11px;
  opacity: 0;
  letter-spacing: none;
  transition: all 0.5s ease-in-out 0.5s;
}

.cardNew:hover {
  border-radius: 0;
  transform: scale(1.1);
}
.cardNew:hover .logo_ .logo_1 {
  left: -180px;
  width: 180px !important;
  opacity: 0;
}
.cardNew:hover .logo_ .logo_2 {
  left: 0px !important;
    width: 150px !important;
    opacity: 1;
}

.cardNew:hover .logo_ {
  width: 150px;
  animation: opacity 1s ease-in-out;
}

.cardNew:hover .border {
  inset: 10px;
  opacity: 1;
  transform: rotate(0);
}
.inside{
  box-shadow: 0 0 4px;
  box-shadow: 1px 1px 5px blue;
    border-radius: 6px;
}

.cardNew:hover .bottom-text {
  letter-spacing: 3px;
  opacity: 1;
  transform: translateX(-50%);
}

.cardNew:hover .content .logo_-bottom-text {
  opacity: 1;
  letter-spacing: 9.5px;
}

.cardNew:hover .trail {
  animation: trail 1s ease-in-out;
}

@keyframes opacity {
  0% {
    border-right: 1px solid transparent;
  }

  10% {
    border-right: 1px solid #bd9f67;
  }

  80% {
    border-right: 1px solid #bd9f67;
  }

  100% {
    border-right: 1px solid transparent;
  }
}

@keyframes trail {
  0% {
    background: linear-gradient(90deg, rgba(189, 159, 103, 0) 90%, rgb(189, 159, 103) 100%);
    opacity: 0;
  }

  30% {
    background: linear-gradient(90deg, rgba(189, 159, 103, 0) 70%, rgb(189, 159, 103) 100%);
    opacity: 1;
  }

  70% {
    background: linear-gradient(90deg, rgba(189, 159, 103, 0) 70%, rgb(189, 159, 103) 100%);
    opacity: 1;
  }

  95% {
    background: linear-gradient(90deg, rgba(189, 159, 103, 0) 90%, rgb(189, 159, 103) 100%);
    opacity: 0;
  }
}
.mob_250 .col-md-4{
  width: 250px !important;
}