/* Fast.css */

.fast-container {
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}


.fast-title {
  font-size: 6rem;
  margin-bottom: 10px;
  margin-top: 0px;
}

.arrowimg{
  padding-top: -80px;
  
}

.fast-para {
  font-size: 1.2rem;
  color: #555;
  text-align: center;
  width: fit-content;
  margin-top: 0;
  margin-bottom: 30px;
}

.bouncing-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap; /* Allow items to wrap to the next line on smaller screens */
  margin-bottom: 30px;
}

.bouncing-box h5 {
  margin: 0 10px;
  padding: 10px;
  border-radius: 8px;
  color: #fff;
}

/* Different background colors for each h5 */
.falling-box h5:nth-child(1) {
  background-color: #dcdcd5;
}

.falling-box h5:nth-child(2) {
  background-color: #dcdcd5;
}

.falling-box h5:nth-child(3) {
  background-color: #dcdcd5;
}

.falling-box h5:nth-child(4) {
  background-color: #dcdcd5;
}

.falling-box h5:nth-child(5) {
  background-color: #dcdcd5;
}

.falling-box h5:nth-child(6) {
  background-color: #dcdcd5;
}


/* Set a random property for each h5 element */
.bouncing-box h5 {
  
  margin: 0 10px;
  padding: 10px;
  border-radius: 100px;
  color: #fff;
 
}

.falling-box{

  display: flex;
}

.falling-box h5 {
  
  margin: 0 10px;
  padding: 10px;
  border-radius: 100px;
  color: black;
 
}

.falling-box h5:hover {
  cursor: pointer;
 
}

/* Different background colors for each h5 */
.bouncing-box h5:nth-child(1) {
  background-color: #ff5733;
}

.bouncing-box h5:nth-child(2) {
  background-color: #33ff57;
}

.bouncing-box h5:nth-child(3) {
  background-color: #5733ff;
}

.bouncing-box h5:nth-child(4) {
  background-color: #ff5733;
}

.bouncing-box h5:nth-child(5) {
  background-color: #33ff57;
}

.bouncing-box h5:nth-child(6) {
  background-color: #5733ff;
}


/* Responsive adjustments using media queries */
@media (max-width: 768px) {
  .fast-title {
    font-size: 4rem; /* Adjust font size for smaller screens */
  }

  .fast-para {
    font-size: 1rem; /* Adjust font size for smaller screens */
  }
}


/* bg style */
:root {
  background: radial-gradient(#2c2f3e, #0a0f1c);
  /* overflow: hidden; */
}

.fast-container {
  display: flex;  
  align-items: center;
  justify-content: center;
}

/* This is the glowing center */



