/* Header.css */

.header-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
  /* background-color: #f8f1d4; */
  background-image: url('/public/alter.png'); 
  background-size: cover;
}

.centered-image {
  max-width: 80%;
  height: 200px; /* Adjust the height as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
}

/* Add the following rule for the black box */
.black-box {
  width: 800px; /* Adjust the width as needed */
  height: 310px; /* Adjust the height as needed */
  background-color: #00008b;
  border-radius: 20px;
  overflow: hidden;
}
@media only screen and (max-width:700px){
  .black-box {
      height: 210px;
      width: 300px;
      border-radius: 20px;
  }
}

.black-box h1{
  margin-left: 180px;
}


.smallbox{
  margin-left: 570px;
  margin-top: 120px;
}

.topani1 {
  margin-left: 200px; /* Adjust the left margin as needed */
  margin-top: -50px;
  transform: translateX(6%);
  color: white;
}

.topani2 {
  margin-right: 200px; /* Adjust the right margin as needed */
  margin-top: -50px;
  transform: translateX(-5%);
  color: white;
}

.hero-waves {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 200px; 
  background-color: #2025f3; 
  opacity: 0.5; /* Adjust the opacity as needed */
}
