
@import url("https://fonts.googleapis.com/css2?family=Gluten:wght@700&display=swap");
.phone-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.z_top{
  z-index: 2;
  position: relative;
}
.good_one_h3{
  font-size: 25px;
  color: white;
  box-shadow: 2px 2px 5px white;
  background: #00008b;
  border-radius: 5px;
}
.slick-slide img{
  margin: auto;
}
.absolute_full_width{
  position: absolute;
  content: '';
  width: 150vw;
  max-width: 200vw;
  z-index: 1;
  height: 470px;
  left: -34vw;
  top: 0px;
}
.no_bg{
  background: transparent !important;
}
.mob_animate{
  height: 350px;
  width: 100%;
}
.absolute_full_width_outer{
  position: relative;
  max-width: 100vw;
}
.phonecenter-content {
  text-align: center;
  
}
.phonecenter-content h1 {
    background-color: black;
    color:white;
    font-size: 3em;
    border-radius: 10px;
  }
.phonepic {
 height: 400px;
 width: 250px;   
 box-shadow: 0 0 5px inset;
 border-radius: 25px !important;
}
.phonepic .slick-dots{
  bottom: -6px !important;
}
.insde_mob_view{
  border-radius: 25px !important;
  overflow: hidden;
  border: 5px solid black;
  position: relative;
  box-shadow: 0 0 5px inset;
    background: #cdcdcd;
}
.out_side_mobile_view::after{    
  position: absolute;
  content: '';
  //width: 54%;
  left: 50%;
  height: 18px;
  transform: translate(-50%,0px);
  top: 11px;
  background-color: white;
  border-radius: 0% 0% 17% 17% / 0% 0% 100% 100%;
}
.new_one{
  width: 80vw;
}
.out_side_mobile_view{
  padding: 20px;
  background-color: white;
  border-radius: 5px;
}
.custom_x1{
  top: 50%;
  right: -50%;
  content: '';
  z-index: 5;
  width: fit-content;
  height: fit-content;
}






.outer_css {
  --color-dark: 210 55% 8%;
  --color-dark-static: 210 55% 25%;
  --color-light: 200 60% 90%;
  --font-size: min(20vw + 1rem, 12rem);

  display: grid;
  place-items: center;
  overflow: hidden;
  background-color: hsl(var(--color-dark));
  font-size: var(--font-size);
  font-family: "Gluten", sans-serif;
  perspective: 40em;
}

.css {
  --rotate: 70deg;
  --transition: 400ms cubic-bezier(0.25, 1, 0.5, 1);

  display: flex;
  position: relative;
  top: 0.25em;
  color: hsl(var(--color-dark-accent));
  user-select: none;
  perspective: 2em;
  transform: scale(0.9);
  transform-style: preserve-3d;
  transition: var(--transition);
  transition-property: perspective, transform;
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>hi</text></svg>")
      16 0,
    auto;
}

.css::before,
.css::after {
  --s: calc(var(--font-size) * 2);

  content: "";
  position: absolute;
  left: 50%;
  width: var(--s);
  height: calc(var(--s) / 2);
  background-color: hsl(var(--color-light));
  opacity: 0;
  transform: translateZ(-1vmin) translateX(-50%) scale(0);
  transition: var(--transition);
  transition-property: opacity, transform;
  pointer-events: none;
}

.css::before {
  bottom: 50%;
  border-top-left-radius: 360px;
  border-top-right-radius: 360px;
  transform-origin: bottom center;
}

.css::after {
  --s: calc(var(--font-size) * 2);

  top: 50%;
  transform-origin: top center;
  transform: translateX(-50%) rotateX(var(--rotate)) scale(0.001);
  background: linear-gradient(
    to bottom,
    hsl(var(--color-light)) 0%,
    hsla(var(--color-light) / 0.951) 6.3%,
    hsla(var(--color-light) / 0.888) 12.1%,
    hsla(var(--color-light) / 0.815) 17.6%,
    hsla(var(--color-light) / 0.734) 22.8%,
    hsla(var(--color-light) / 0.647) 27.9%,
    hsla(var(--color-light) / 0.557) 33%,
    hsla(var(--color-light) / 0.466) 38.3%,
    hsla(var(--color-light) / 0.377) 43.8%,
    hsla(var(--color-light) / 0.292) 49.7%,
    hsla(var(--color-light) / 0.213) 56.1%,
    hsla(var(--color-light) / 0.143) 63.1%,
    hsla(var(--color-light) / 0.084) 70.9%,
    hsla(var(--color-light) / 0.039) 79.5%,
    hsla(var(--color-light) / 0.01) 89.2%,
    hsla(var(--color-light) / 0) 100%
  );
}

.css span {
  --rotate-letter: 34deg;

  display: flex;
  flex-direction: column;
  line-height: 0.475;
  color: hsl(var(--color-dark-static));
  perspective: 1em;
  transition: var(--transition);
  transition-property: color, transform;
  transform-style: preserve-3d;
}

.css span::after {
  display: block;
  color: hsl(var(--color-dark));
  transform-origin: top center;
  transform: rotateX(var(--rotate)) scaleY(1.25);
  pointer-events: none;
  z-index: -1;
}

.css :first-child::after {
  content: "c";
}

.css :not(:first-child)::after {
  content: "s";
}

.css :first-child {
  transform-origin: left center;
}

.css :last-child {
  transform-origin: right center;
}

/* Hover */
.css {
  transform: scale(1.25);
}

.css::before {
  opacity: 1;
  transform: translateZ(-1vmin) translateX(-50%) scale(1.05);
}

.css::after {
  opacity: 1;
  transform: translateZ(-1vmin) translateX(-50%) rotateX(var(--rotate))
    scale(1.05);
}

.css span {
  color: hsl(var(--color-dark));
  transform: translateZ(6vmin);
}

.css :first-child {
  transform: translateX(20%) rotateY(calc(var(--rotate-letter) * -1));
}

.css :last-child {
  transform: translateX(-20%) rotateY(var(--rotate-letter));
}

* {
  box-sizing: border-box;
}

:root {
  --gold: #ffb338;
  --light-shadow: #77571d;
  --dark-shadow: #3e2904;
}
body {
  margin: 0;
}
.wrapper {
  background: radial-gradient(#272727, #1b1b1b);
  display: grid;
  grid-template-areas: 'overlap';
  place-content: center;
  text-transform: uppercase;
  height: 100vh;
}
.wrapper > div {
  background-clip: text;  
  -webkit-background-clip: text;
  color: #363833;
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
  font-size: clamp( 3em, 18vw, 15rem);
  grid-area: overlap;
  letter-spacing: 1px;
  -webkit-text-stroke: 4px transparent;
}
div.bg {
  background-image: repeating-linear-gradient( 105deg, 
    var(--gold) 0% , 
    var(--dark-shadow) 5%,
    var(--gold) 12%);
  color: transparent;
  filter: drop-shadow(5px 15px 15px black);
  transform: scaleY(1.05);
  transform-origin: top;
}
div.fg{
  background-image: repeating-linear-gradient( 5deg,  
    var(--gold) 0% , 
    var(--light-shadow) 23%, 
    var(--gold) 31%);
  color: #1e2127;
  transform: scale(1);
}
@media only screen and (max-width:1035px){
  .container.gap-28.new_one.justify-between{
    gap: 10px !important;
  }
}