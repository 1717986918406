/* Pricing.css */

.pricing-container {
  /* text-align: center; */
  padding: 20px;
  color: white;
  background-color: #1d1f11;
}

.pricing-table {
  width: 80%;
  margin: auto;
  border-collapse: collapse;
  margin-top: 20px;
  
}

.pricing-row {
  border: 1px solid #ddd;
  background-color: #f9f9f9;
}

.pricing-row td, .pricing-row th {
  padding: 15px;
  text-align: center;
}
.overflow_auto{
  overflow: auto;
}

.feature-row td {
  padding: 15px;
  text-align: left; /* Adjusted text alignment for feature names */
}

.features {
  list-style: none;
  padding: 0;
  margin: 10px 0;
}

.get-started-button {
  background-color: #000000;
  color: #fff;
  padding: 12px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  font-size: 10px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.get-started-button:hover {
  background-color: #d4ff8c;
  color:black;
}
