.stepcontainer {
  display: flex;
  width: 100%;
  height: 600px;
  overflow-y: hidden;
  overflow-x: hidden;
}

.stepleft-column  {
  flex: 0 0 50%;
  background-color: #f7f7f0;
  padding: 20px;
  /* display: flex; */
  align-items: center;
}

.stepleft-column h1 {
  display: flex;
  font-size: 3em;
  padding-left: 10px;
}

.stepright-column {
  flex: 0 0 50%;
  background-color: #f7f7f0;
  padding: 20px;
}

.step {
  display: flex;
  align-items: center;
}

.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #cdf683;
  color: #333;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.connector {
  height: 90px;
  width: 2px;
  background-color: #222221;
  margin: 0 15px;
}

.step-text {
  margin-bottom: 10px;
  color: #000000;
}

.step-text h1 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #000000;
}

.step-text p {
  font-size: 16px;
  margin-bottom: 10px;
  color: #000000;
  text-wrap: wrap;
}

.step-text {
  margin-bottom: 10px;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Responsive adjustments using media queries */
@media (max-width: 1200px) {
  .stepleft-column,
  .stepright-column {
    padding: 10px;
  }
}

@media (max-width: 992px) {
  .circle,
  .connector {
    margin: 10px 0;
  }
}

@media (max-width: 768px) {
  .stepcontainer {
    flex-direction: column; /* Change to a column layout on smaller screens */
    height: auto; /* Allow the container to adjust its height based on content */
  }

  .stepleft-column,
  .stepright-column {
    flex: 1;
    width: 100%;
    margin-bottom: 20px; /* Add some spacing between columns on smaller screens */
  }

  .step {
    flex-direction: column;
    align-items: flex-start;
  }
}
