/* Style for the container div */
.faqs-container {
  display: flex;
  flex-wrap: wrap; /* Allow columns to wrap to the next row on smaller screens */
  background-color: #c2bcff;
}

/* Style for each column */
.ecolumnleft {
  flex: 1; /* This makes both columns take equal width */
  padding: 20px; /* Add padding for spacing */
  border: 1px solid #ccc; /* Add border for visualization */
  box-sizing: border-box; /* Include padding and border in the column width */
}

.ecolumnright {
  flex: 1; /* This makes both columns take equal width */
  padding: 20px; /* Add padding for spacing */
  border: 1px solid #ccc; /* Add border for visualization */
  box-sizing: border-box; /* Include padding and border in the column width */
  display: flex; /* Enable flexbox for centering */
  flex-direction: column; /* Stack items vertically in each column */
  align-items: center; /* Center content horizontally */
}



/* Media query for tablets and smaller devices */
@media (max-width: 768px) {
  .ecolumnleft{
    flex: 1 0 100%; /* Full width for each column on smaller screens */
    margin-bottom: 10px; /* Add margin between columns */
  }
  .ecolumnright{
    flex: 1 0 100%; /* Full width for each column on smaller screens */
    margin-bottom: 10px; /* Add margin between columns */
  }
}


/* Media query for tablets and smaller devices */
@media (max-width: 1400px) {
  .ecolumnleft {
    width:fit-content;
  }

  .ecolumnright img{
    width:fit-content;
    
  }
}


