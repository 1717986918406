

.flex{
    text-decoration: none;
    align-items: center;
}

.flex:hover li{
    color:rgb(247, 247, 250)
}

.text-sm{
   margin-left: 17%;
   color: white;
}

.inline-flex{
    width:40%;
    padding: 0 !important;
}
.footersection{
    background-color: #1237ab;
    color:white;
}