/* Add this to your existing CSS file or create a new one */

.real-stories {
  text-align: center;
  padding: 20px;
  background-color: black;
  color: white; /* Set text color to white */
}

.real-stories h2 {
  font-size: 3.1rem;
}

.realmove {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line on small screens */
  justify-content: space-around; /* Adjust as needed */
  overflow-x: hidden; /* Hide horizontal overflow */
}

.realmove > div {
  flex: 1 0 calc(30% - 20px); /* Adjust as needed */
  margin: 10px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box; /* Include padding and border in the total width */
  max-width: calc(30% - 20px); /* Ensure a maximum width */
  box-sizing: border-box;
}

.realmove p {
  font-size: 1.1rem;
  color: white; /* Set text color to white */
  margin-bottom: 10px;
}

.realmove h3 {
  font-size: 1.4rem;
  color: white; /* Set text color to white */
  margin-bottom: 5px;
}

.realmove p:last-child {
  color: white;
  font-style: italic;
  margin-top: 0;
}

/* Media Queries for Responsive Design */

/* Small Screens */
@media screen and (max-width: 768px) {
  .realmove > div {
    flex: 1 0 calc(50% - 20px); /* Adjust as needed for smaller screens */
    max-width: calc(50% - 20px); /* Ensure a maximum width for smaller screens */
  }
}

/* Medium Screens */
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .realmove > div {
    flex: 1 0 calc(33.333% - 20px); /* Adjust as needed for medium screens */
    max-width: calc(33.333% - 20px); /* Ensure a maximum width for medium screens */
  }
}

/* Large Screens */
@media screen and (min-width: 1025px) {
  .realmove > div {
    flex: 1 0 calc(25% - 20px); /* Adjust as needed for larger screens */
    max-width: calc(25% - 20px); /* Ensure a maximum width for larger screens */
  }
}
