.footrowcon {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 columns with equal width */
    grid-gap: 20px; /* Adjust as needed for spacing between columns */
    background-color: #0303cd;
  }
  
  .fcolumn {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
    /* justify-content: center; Center content vertically */
    background-color: #0303cd;
    color: white;
    border-right: 2px solid white;
  }
  
  .fe{
    background-color: black;
    font-weight: 4em;
    font-size: larger;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 30px;
    margin-top: 10px;
  }

  .fc{
    color: white;
  }