body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.under_phone_card{
  background: #ffffff;
}
.items-end{
  align-items: end !important;
}
.under_experience_card{
  border: 0.5px solid #ffefef;
  height: 95px !important;
  background: white;
}
.under_experience_card p,.under_experience_card h1{
  margin-bottom: 0px !important;
}
@media only screen and (max-width:744px){
  .column_at_744{
    flex-direction: column;
  }
  .center_at_744{
    justify-content: center;
  }
  .full_radus_at744{
    border-radius: 15px !important;
  }
}
@media only screen and (max-width:934px){
  .experience{
    flex-direction: column-reverse;
  }
  .radius_full_at_934{
    border-radius: 15px !important;
  }
}
@media only screen and (max-width:662px){
  .experience .grid.grid-cols-4{
    grid-template-columns: auto auto;
  }
  .under_experience_card:nth-child(7){
    grid-row-start: 9;
  }
}