/* Aboutus.css */

.aboutus-container {
  display: flex;
  padding: 2rem;
  background-color: #dcdbff;
  /* background-image: url('/public/q.svg'); */
  background-image: url('/public/blob.svg'); 
}
.img{
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.right-aboutus,.card{
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;   
}

.aboutus-container1 {
  display: flex;
  padding: 2rem;

}
.left-aboutus {
  flex: 1;
  padding-right: 1rem; /* Add padding for spacing */
}

.right-aboutus {
  flex: 1;
  padding-left: 1rem; /* Add padding for spacing */
}

.ty{
  background-color: black;
  color:white;
  text-align: center;
  font-size: 35px;
  font-weight: bolder;
  margin-bottom: 10px;
  border-radius: 5px;
}

.shine {
  font-size: 2em;
  width: fit-content;
  margin: 5px auto;
  font-weight: 900;
  color: rgba(255, 255, 255, 0.3) !important;
  background: #222 -webkit-gradient(
      linear,
      left top,
      right top,
      from(#222),
      to(#222),
      color-stop(0.5, #fff)
    ) 0 0 no-repeat;
  background-image: -webkit-linear-gradient(
    -40deg,
    transparent 0%,
    transparent 40%,
    #fff 50%,
    transparent 60%,
    transparent 100%
  );
  background-clip: text;
  background-size: 50px;
  animation: zezzz;
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
@keyframes zezzz {
  0%{
    background-position: -20px;
  }
  100% {
    background-position: right;
  }
}
.first_card{
  box-shadow:2px 2px 5px black inset;
  padding: 10px;
  border: 20px solid rgb(207, 207, 207);
  overflow: hidden;
}
.first_card img{
  display: block;
  margin: auto;
}
.row.nowrap{
  flex-wrap: nowrap !important;
}
.profile_card{
  background: #00008b;
/* background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%); */
box-shadow: 2px 2px 5px;
border-radius: 15px;
}
.profile_card img{
  border-radius: 50%;
  box-shadow: 2px 2px 10px white;
  padding: 5px;
  width: 200px;
  height: 200px;
  /* box-shadow: 2px 2px 10px; */
}
.misson_row .col-md-4 .m-4{
  box-shadow: 2px 2px 5px;
  border-radius: 5px;
  padding: 5px 15px;
}
.misson_row .col-md-4 .m-4:hover{
  box-shadow: 0 0 10px;
}
.meet_our_team{
  background-color: #0093E9;
background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);

}
.teer_nishan1{    
  width: 15px;
  height: 21px;
  display: block;
  margin: auto;
  background: linear-gradient(to right, #d1cbcb, #3a3636);
  background: linear-gradient(to right, rgba(2, 0, 36, 1) 0%, rgba(9, 9, 121, 1) 35%, rgba(0, 212, 255, 1) 100%);
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
}
.teer_nishan2{
  width: 80vw;
  height: 15px;
  background: linear-gradient(to bottom, #d1cbcb, #3a3636);
  background: linear-gradient(to bottom, rgba(2, 0, 36, 1) 0%, rgba(9, 9, 121, 1) 35%, rgba(0, 212, 255, 1) 100%);;
  display: block;
  margin: auto;
  border-top-right-radius: 55px;
  border-top-left-radius: 55px;
}
.teer_nishan_top_box .col-md-3,.teer_nishan_top_box .col-md-4{
  position: relative;
}
.teer_nishan_top_box .col-md-3::after{
  position: absolute;
  content: '';
  top: 8px;
  left: 50%;
  transform: translate(-0%,-50%);
  width: 15px;
  height: 16px;
  background: linear-gradient(to right, rgba(2, 0, 36, 1) 0%, rgba(9, 9, 121, 1) 35%, rgba(0, 212, 255, 1) 100%);
}
.teer_nishan_top_box .col-md-4::after{
  position: absolute;
  content: '';
  top: 8px;
  left: 50%;
  transform: translate(-0%,-50%);
  width: 15px;
  height: 16px;    
background: linear-gradient(to right, rgb(11 27 37) 0%, rgb(29 42 48) 35%, rgb(139 166 175) 100%) !important;
}
.awards_and_recognition{
  background: linear-gradient(45deg, black, transparent);
}

@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

.mission2 {
/* solid background */
background: rgb(0,212,255);

/* gradient background*/
background: linear-gradient(45deg, rgba(0,212,255,1) 0%, rgba(11,3,45,1) 100%);    

/* photo background */
background-image: url(https://images.unsplash.com/photo-1619204715997-1367fe5812f1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1889&q=80);
background-size: cover;
background-position: center;  

display: flex;
flex-direction: column;
align-items: center;
justify-content: center;    
/* height: 100vh;   */
}

.container2 {
backdrop-filter: blur(16px) saturate(180%);
-webkit-backdrop-filter: blur(16px) saturate(180%);
background-color: #00008b;
border-radius: 12px;
border: 1px solid rgba(255, 255, 255, 0.125);  
padding: 38px;  
filter: drop-shadow(0 30px 10px rgba(0,0,0,0.125));
display: flex;
flex-direction: column;
align-items: center;
justify-content:center;
text-align: center;

}

.wrapper2 {
width: 100%;
height: 100%;

}

.banner-image2 {
/* background-image: url(https://images.unsplash.com/photo-1641326201918-3cafc641038e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1887&q=80); */
/* background-position: center;
background-size: cover; */
/* height: 300px; */
width: 100%;
border-radius: 12px;
/* border: 1px solid rgba(255,255,255, 0.255) */
}

h1{
font-family: 'Righteous', sans-serif;
color: rgba(255,255,255,0.98);
text-transform: uppercase;
font-size: 2.4rem;
}

p {
color: #fff;
font-family: 'Lato', sans-serif;
/* text-align: center; */
font-size: 0.8rem;
line-height: 150%;
letter-spacing: 2px;
text-transform: uppercase;
}

.button-wrapper2{
margin-top: 18px;
}

.btn2 {
border: none;
padding: 12px 24px;
border-radius: 24px;
font-size: 12px;
font-size: 0.8rem;  
letter-spacing: 2px;  
cursor: pointer;
}

.btn2 + .btn2 {
margin-left: 10px;
}

.outline2 {
background: transparent !important;
color: rgba(0, 212, 255, 0.9) !important;
border: 1px solid rgba(0, 212, 255, 0.6) !important;
transition: all .3s ease;

}

.outline2:hover{
transform: scale(1.125);
color: rgba(255, 255, 255, 0.9);
border-color: rgba(255, 255, 255, 0.9);
transition: all .3s ease;  
}

.fill2 {
background: rgba(0, 212, 255, 0.9) !important;
color: rgba(255,255,255,0.95) !important;
filter: drop-shadow(0);
font-weight: bold;
transition: all .3s ease; 
}

.fill2:hover{
transform: scale(1.125);  
border-color: rgba(255, 255, 255, 0.9);
filter: drop-shadow(0 10px 5px rgba(0,0,0,0.125));
transition: all .3s ease;    
}
.bg_mission2{
background: linear-gradient(to bottom, rgb(11 27 37) 0%, rgb(29 42 48) 35%, rgb(139 166 175) 100%) !important;
}
.bg_mission1{
background: linear-gradient(to right, rgb(11 27 37) 0%, rgb(29 42 48) 35%, rgb(139 166 175) 100%) !important;
}


/* add support css */

.accordion-item {
font-family: "SF Pro";
max-width: 100%;
padding: 0.875rem;
font-size: 1rem;
border: 1.5px solid #000;
border-radius: 0.5rem;
box-shadow: 2.5px 8px 0 #000;
outline: none;
transition: ease 0.25s;
margin-left: 5rem;
margin-bottom: 1rem;

}

/* Media query for mobile devices */
@media (max-width: 555px) {
.accordion-item {
  padding: 0.5rem; /* Reduced padding for smaller screens */
  font-size: 0.875rem; /* Slightly smaller font size */
  border: 1px solid #000; /* Thinner border */
  box-shadow: 1.5px 5px 0 #000; /* Less pronounced shadow */
  margin: 1.5rem;
  text-align: center;
  margin-right: 3rem;
}
}


.accordion:focus {
box-shadow: 5.5px 10px 0 black;
}

/* Optional: Add a media query for larger tablets if needed */
@media (min-width: 450px) and (max-width: 550px) {
.accordion-item {
  padding: 0.75rem; /* Slightly reduced padding for tablets */
  font-size: 0.9375rem; /* Slightly smaller font size for tablets */
}
}


@media(max-width: 555px){
.cards{
  padding: 1rem;
  
}
}