/* ColumnsWithIcons.css */

.whitecolumns-container {
  display: flex;
  flex-wrap: wrap;
  background-color: #f7f7f0;
  padding: 100px;
  box-sizing: border-box;
  /* padding: 40px; Add padding from all sides */
}

.whitecolumn {
  flex: 1 0 25%; /* 25% width for each column */
  box-sizing: border-box;
  text-align: left;
  padding: 25px;
  position: relative;
  overflow: hidden;
  transition: transform 0.2s, box-shadow 0.3s; /* Added box-shadow transition */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Updated box shadow for an attractive effect */
  border-radius: 10px; /* Rounded corners for a softer look */
  margin: 15px; /* Added margin for space between columns */
}

.whitecolumn:hover {
  transform: scale(1.05); /* Increase size on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhance box shadow on hover */
}

.whitecolumnicon {
  font-size: 150px;
  margin-bottom: 10px;
  color: white;
  background-color: green; /* Set the background color to green */
  border-radius: 50%; /* Make it a circle using border-radius */
  padding: 15px; /* Adjust padding for the circle */
  display: inline-block; /* Ensure inline-block for proper circle display */
  text-align: center; /* Center the content inside the circle */
  box-sizing: border-box; /* Include padding in the overall size */
}

.whitecolumnicon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px; /* Adjust width to match the desired size */
  height: 48px; /* Adjust height to match the desired size */
  background-color: #e4cd9f; /* Background color */
  border-radius: 50%; /* Make it a circle */
  overflow: hidden; /* Ensure the icon stays within the circular container */
}


.whitecolumn h3 {
  color: #181a0e;
  margin: 0; /* Remove default margin */
  padding-left: 1px;
  
}

.whitecolumn p {
  color: #64686d;
  margin: 0; /* Remove default margin */
  padding-left: 1px;
  
}

/* Responsive styles */
@media (max-width: 1200px) {
  .whitecolumn {
    flex: 1 0 33.33%; /* Adjust width for medium-sized screens */
  }
}

@media (max-width: 768px) {
  .whitecolumn {
    flex: 1 0 50%; /* Adjust width for small screens */
  }
}

@media (max-width: 480px) {
  .whitecolumn {
    flex: 1 0 100%; /* Adjust width for extra small screens */
  }
}

/* Responsive for clumns */
@media (max-width: 1200px) {
  .whitecolumns-container {
    padding: 40px; /* Adjust padding for medium-sized screens */
  }
}

@media (max-width: 768px) {
  .whitecolumns-container {
    padding: 20px; /* Adjust padding for small screens */
  }
}

@media (max-width: 480px) {
  .whitecolumns-container {
    padding: 10px; /* Adjust padding for extra small screens */
  }
}
