.box_new_thing {
        padding: 2px !important;
        position: relative !important;
        width: 850px !important;
        margin: auto;
        border-radius: 8px !important;
        background: #ffffff !important;
        overflow: hidden !important;
      }
      .box_new_thing::before {
        content: "";
        position: absolute;
        top: -100%;
        left: -50%;
        width: 380px;
        height: 420px;
        background-image: linear-gradient(135deg, #00008b 10%, #9708cc 100%);
        background-image: linear-gradient(135deg, #e1d4cd 10%, #9708cc 100%);
        background: linear-gradient(
          to left,
          #ffbd7a,
          #fe8bbb,
          #000000 5%,
          transparent
        );
        transform-origin: bottom right;
        animation: animate 6s linear infinite;
      }
      .spec3-conatiner img{
        height: 40px;
      }
      .box_new_thing > * {
        z-index: 10;
      }
      .box_new_thing .inside_box {
        border-radius: 6px;
        width: 100%;
        height: 100%;
        background-color: #212121;
        background-color: white;
        z-index: 50;
        position: relative;
      }
      @keyframes animate {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
      .inside_box img {
        height: 40px;
        margin: 15px;
      }
      .svg-frame {
        position: relative;
        width: 300px;
        height: 150px;
        transform-style: preserve-3d;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .svg-frame svg {
        position: absolute;
        transition: 0.5s;
        z-index: calc(1 - (0.2 * var(--j)));
        transform-origin: center;
        width: 344px;
        height: 344px;
        fill: none;
      }

      .svg-frame svg #center {
        transition: 0.5s;
        transform-origin: center;
      }

      .svg-frame:hover svg #center {
        transform: rotate(-30deg) translateX(45px) translateY(-3px);
      }

      #out2 {
        animation: rotate16 7s ease-in-out infinite alternate;
        transform-origin: center;
      }

      #out3 {
        animation: rotate16 3s ease-in-out infinite alternate;
        transform-origin: center;
        stroke: #ff0;
      }

      #inner3,
      #inner1 {
        animation: rotate16 4s ease-in-out infinite alternate;
        transform-origin: center;
      }

      #center1 {
        fill: #ff0;
        animation: rotate16 2s ease-in-out infinite alternate;
        transform-origin: center;
      }

      @keyframes rotate16 {
        to {
          transform: rotate(360deg);
        }
      }
      .animate-ripple {
        animation: ripple 3.4s ease infinite;
      }
      @keyframes ripple {
        0%,
        100% {
          transform: translate(-50%, -50%) scale(1);
        }

        50% {
          transform: translate(-50%, -50%) scale(0.9);
        }
      }
      .transform-gpu{
        transform:translate3d(0, 0, 0) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1);
      }
      .new_2{width: 210px; height: 210px; opacity: 0.24; animation-delay: 0s;
      }
      .new_3{
        width: 280px; height: 280px; opacity: 0.21; animation-delay: 0.06s;
      }
      .new_4{width: 350px; height: 350px; opacity: 0.18; animation-delay: 0.12s;
      }
      .new_5{width: 420px; height: 420px; opacity: 0.15; animation-delay: 0.18s;
      }
      .new_6{width: 490px; height: 490px; opacity: 0.12; animation-delay: 0.24s;
      }
      .new_7{width: 560px; height: 560px; opacity: 0.09; animation-delay: 0.3s;
      }
      .new_8{width: 630px; height: 630px; opacity: 0.06; animation-delay: 0.36s;
      }
      .new_9{width: 700px; height: 700px; opacity: 0.03; animation-delay: 0.42s;
      }

      .text_good {
        font-family: sans-serif;
        color: #3c3c3c;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        flex-direction: column;
      }
      
      @keyframes textShine {
        0% {
          background-position: 0% 50%;
        }
        100% {
          background-position: 100% 50%;
        }
      }
      .text_good p {
        position: relative;
        font-size: 20px;
        text-align: center;
        font-weight: bold;
        padding: 5px;
        border-radius: 5px;
        margin: 5px;
        background: linear-gradient(
          to right,
          #7953cd 20%,
          #00affa 30%,
          #0190cd 70%,
          #764ada 80%
        );
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
        background-size: 500% auto;
        animation: textShine 5s ease-in-out infinite alternate;
        overflow: hidden;
      }
      .text_good{
        position: relative;
        border-radius: 5px;
      }
      /* .text_good::after{
        position: absolute;
        top: 0;
        left: 0;
        width: 200%;
        height: 200%;
        z-index: 45;
        content: '';
        background-color: #4158D0;
        background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
        border-radius: 5px;
        background-size: 200%;
        background-origin: content-box;
        animation: animate_it_round 1s ease-in-out infinite;
      } */
      @keyframes animate_it_round2 {
        0%{
          transform: rotate(0deg);
        }
        100%{
          transform: rotate(360deg);
        }
      }
      .text_good p {
        z-index: 45454545;
        position: relative;
      }
      
img.top_index{
  position: relative;
  width: 130px;
  height: 130px;
  z-index: 5564454;
  background: #4d4dae;
    border-radius: 50%;
}
.outer_p{
  background: white !important;
    position: relative;
    z-index: 553;
    margin: 1px;
    border-radius: 5px;
}
.bg-neutral-400{
  background: #00008b !important;
}
.line{    
  width: 100%;
  height: 2px;
  background-image: linear-gradient(to right, #000000 0%, #ff0000 51%, #000000 100%);
  background-position: 0%;
  position: absolute;
  transition: 1.5s;
  background-size: 200% auto;
}
.light-beam {
  width: 200px;
  height: 200px;
  background: radial-gradient(circle, rgba(255, 255, 0, 0.8) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
}
.line::after{
  position: absolute;
  content: '';    
  left: -8px;
  top: -9px;
  width: 20px;
  height: 20px;
  background: radial-gradient(circle, rgba(255, 255, 0, 0.8) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
  background: radial-gradient(circle, rgba(251, 251, 251, 0.8) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
  animation:animate_it_center_to_top 2s ease-in-out infinite;
}
@keyframes animate_it_center_to_top {
  0%{   
    left: -8px;
    top: -9px;
  }
  100%{   
    left:600px;
    top: -9px;
  }
  
}
@keyframes animate_it_in {
  0%{
    background-position: 0%;
    background-size: 1000% auto;
  }
  50%{
    background-position: right center;
    background-size: 200% auto;
  }
  100%{
    background-position: 0%;
    background-size: 2000% auto;
  }
}
.line.top.right{
  transform: rotate(33deg);
}
.line.top.left{
  transform: rotate(146deg);
}
@media only screen and (max-width:850px){
  img.top_index{
    width: 80px;
    height:auto;
  }
  .box_new_thing{
    width: 100% !important;
  }
}
@media only screen and (max-width:458px){
  .box_new_thing .relative.flex.h-full.w-full.items-center.justify-center.overflow-hiddens.rounded-lg.bg-background.p-20{
  padding: 10px !important;
  margin: 0px !important;
  width: 245px;
  height: 113px;
  }
}